import React from "react";
import NavbarReact2 from "../components/NavbarReact2";
import ProductoDetalle from "../components/ProductoDetalle";
import Whatsapp from "../components/Whatsapp";

export default function ProyectoC() {
  return (
    <div>
      <NavbarReact2 />
      <ProductoDetalle />
      <Whatsapp />
    </div>
  );
}
