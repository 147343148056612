import React from "react";
import NavbarReact from "../components/NavbarReact";
import ProyectoVillageG from "../components/ProyectoVillageG";
import Whatsapp from "../components/Whatsapp";

export default function VillageGolf() {
  return (
    <div>
      <NavbarReact />
      <ProyectoVillageG />
      <Whatsapp />
    </div>
  );
}
