import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import SliderProyectoDetalle from "./SliderProyectoDetalle";
import Papa from "papaparse";
import { useParams } from "react-router";
import Formulario from "./Formulario";

export default function ProductoDetalle() {
    const params = useParams();

    const [producto, setProducto] = useState({});
    const getProducto = async () => {
        const response = await axios.get(
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vSraX23xYsv1qZOszLpDJJt6Zsz6532X42wpWcxuVgpy2KdIglHkZvk8umDgmPRmUy1dyR3gb_4TRrN/pub?output=csv"
        );

        const productos = Papa.parse(response.data, { header: true });
        const propiedad1 = productos.data;

        const propiedadFiltrada = propiedad1.filter(
            (propiedad) => propiedad.id === params.id
        );
        setProducto(propiedadFiltrada[0]);
    };
    useEffect(() => {
        getProducto();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const {
        direccion,
        localidad,
        operacion,
        tipo,
        datos,
        plantabaja,
        plantalata,
    } = producto;
    return (
        <>
            <Container>
                <div className="tamaño-slider mx-auto">
                    <div>
                        <SliderProyectoDetalle producto={producto} />
                    </div>
                    <div className="color-blanco-fondo p-5">
                        <p className="m-0">
                            Ubicado en {direccion} - {localidad}.
                            {tipo === "Casa" || tipo === "Oficina" || tipo === "Cohera" ? " Una " : " Un "} 
                            excelente {tipo?.toLowerCase()} que esta {operacion === "Venta" ? " a la venta." : " para alquilar."}
                        </p>
                    </div>
                    <div className=" mt-5">
                        <p className="tamaño-medio m-0">Características:</p>
                        <pre className="peso-regular tamaño-medio wrap-pre">{datos}</pre>
                    </div>
                    <div className="">
                        <pre className="peso-regular tamaño-medio wrap-pre">
                            {plantabaja}
                        </pre>
                    </div>
                    <div className="">
                        <pre className="peso-regular tamaño-medio wrap-pre">
                            {plantalata}
                        </pre>
                    </div>
                </div>
            </Container>
            <div className="mt-5 ">
                <h1 className="text-center color-azul mt-5 mx-4 tamaño-grande peso-bold">
                    Déjanos tus datos <br /> y un asesor especializado te
                    contactará
                </h1>
                <Formulario />
            </div>
        </>
    );
}
