import React from "react";
import ReactPlayer from "react-player";

export default function BannerVientoSur() {
  return (
    <>
      <div className="d-flex justify-content-center">
        <ReactPlayer
          className="video cover-container d-none d-sm-flex "
          url={
            "https://res.cloudinary.com/dacvbuvyc/video/upload/v1647892742/Videos/Viento_sur_descktop_s7wiaf_1_o0aqbw.mp4"
          }
          muted
          loop
          playing
          width="100%"
          height="100%"
        ></ReactPlayer>
        <ReactPlayer
          className="video cover-container d-sm-none d-flex"
          url={
            "https://res.cloudinary.com/dacvbuvyc/video/upload/v1647892726/Videos/FINAL_CUADRADO_l4wrwh_1_i99gdv.mp4"
          }
          muted
          loop
          playing
          width="100%"
          height="100%"
        ></ReactPlayer>
      </div>
      <div className="color-blanco-fondo p-5">
        <h1 className="text-center color-azul peso-bold px-3">
          Viento Sur tiene todo lo que la familia necesita para VIVIR FELIZ
        </h1>
      </div>
    </>
  );
}
