import React from "react";

export default function DescripcionVG() {
  return (
    <div className="container-fluid mb-5">
      <div className="row">
        <div className="col-lg-4 p-5">
          <p> Departamentos 2 Dormitorios</p>
          <ul>
            <li> 20m2</li>
            <li> Terraza Balcon 12mts2</li>
            <li> Cocina 1.50*4.10mts</li>
            <li> Estar Comedor 3*5.80</li>
            <li> Lavadero</li>
            <li> 2 Dormitorios 3.60*4.10/2.40*3.60mts</li>
            <li> Baño</li>
            <li> Hall</li>
          </ul>
        </div>
        <div className="col-lg-4 p-5 color-blanco-fondo2">
          <p> Departamentos 1 Dormitorios</p>
          <ul>
            <li> 49m2</li>
            <li> Terraza Balcon 2.10*10mts</li>
            <li> Terraza Descubierta</li>
            <li> Cocina 1.50*4.10mts</li>
            <li> Estar Comedor 3*5.80mts</li>
            <li> Lavadero</li>
            <li> Cocina</li>
            <li> Baño</li>
            <li> Dormitorio 3.35*4.10mts</li>
            <li> Hail</li>
          </ul>
        </div>
        <div className=" col-lg-4 p-5">
          <p> Monoambiente</p>
          <ul>
            <li> 47 mt2</li>
            <li> Balcón</li>
            <li> Monoambiente 4.80*4.80mts</li>
            <li> Baño</li>
            <li> Hail</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
