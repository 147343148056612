import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Producto from "../components/Producto";
// import Banner from "../components/Banner";
import Pagination from "../components/Pagination";
import PaginationMobile from "../components/PaginationMobile";
import axios from "axios";
import Papa from "papaparse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";

export default function Productos({ filtro }) {
    let history = useHistory();

    const [propiedades, setPropiedades] = useState([]);
    //state para el value del input
    const [input, setInput] = useState({});
    //state para la paginacion
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(21);

    useEffect(() => {
        const getPropiedades = async () => {
            const response = await axios.get(
                "https://docs.google.com/spreadsheets/d/e/2PACX-1vSraX23xYsv1qZOszLpDJJt6Zsz6532X42wpWcxuVgpy2KdIglHkZvk8umDgmPRmUy1dyR3gb_4TRrN/pub?output=csv"
            );

            const productos = Papa.parse(response.data, { header: true });

            setPropiedades(productos.data);
        };
        getPropiedades();
    }, []);

    //funcion para capturar la opcion elegida por el usuario
    const handleChange = (event) => {
        const { value, name } = event.target;
        const newInput = { ...input, [name]: value };
        setInput(newInput);
    };

    const buscarPropiedad = async () => {
        history.push("/propiedades");
        const response = await axios.get(
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vSraX23xYsv1qZOszLpDJJt6Zsz6532X42wpWcxuVgpy2KdIglHkZvk8umDgmPRmUy1dyR3gb_4TRrN/pub?output=csv"
        );

        const productos = Papa.parse(response.data, { header: true });
        const propiedad1 = productos.data;

        const propiedadFiltrada = propiedad1.filter(
            (propiedad) => !input.tipo || propiedad.tipo === input.tipo
        );
        const propiedadFiltrada2 = propiedadFiltrada.filter(
            (propiedad) =>
                (!input.tipo || propiedad.tipo === input.tipo) &&
                (!input.operacion || propiedad.operacion === input.operacion)
        );
        const propiedadFiltrada3 = propiedadFiltrada2.filter(
            (propiedad) =>
                (!input.tipo || propiedad.tipo === input.tipo) &&
                (!input.operacion || propiedad.operacion === input.operacion) &&
                (!input.localidad || propiedad.localidad === input.localidad)
        );
        setPropiedades(propiedadFiltrada3);
    };

    // limpiar filtrado
    const resetFiltro = async () => {
        const getPropiedades = async () => {
            const response = await axios.get(
                "https://docs.google.com/spreadsheets/d/e/2PACX-1vSraX23xYsv1qZOszLpDJJt6Zsz6532X42wpWcxuVgpy2KdIglHkZvk8umDgmPRmUy1dyR3gb_4TRrN/pub?output=csv"
            );

            const productos = Papa.parse(response.data, { header: true });
            setPropiedades(productos.data);
        };
        getPropiedades();
    };

    // get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = propiedades.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="container">
            <div className="text-center">
                <p className="tamaño-medio peso-bold color-azul mt-5">
                    Casas, departamentos, oficinas, terrenos...
                </p>
                <p className="tamaño-grande peso-bold color-azul">
                    TENEMOS LO QUE ESTÁS BUSCANDO
                </p>
            </div>
            <div className="mb-5 content-select text-center filtro d-flex justify-content-center flex-wrap">
                <Form.Select
                    name="tipo"
                    onChange={handleChange}
                    className="mt-2 "
                    aria-label="Floating label select example"
                >
                    <option value="">Tipo</option>
                    <option value="Departamento">Depto</option>
                    <option value="Casa">Casa</option>
                    <option value="Local">Local</option>
                    <option value="Terreno">Terreno</option>
                    <option value="Galpon">Galpón</option>
                    <option value="Oficina">Oficina</option>
                    <option value="Cochera">Cochera</option>
                </Form.Select>
                <Form.Select
                    name="operacion"
                    onChange={handleChange}
                    className="mt-2"
                    aria-label=""
                >
                    <option value="">Operación</option>
                    <option value="Venta">Venta</option>
                    <option value="Alquiler">Alquiler</option>
                </Form.Select>
                <Form.Select
                    name="localidad"
                    onChange={handleChange}
                    className="mt-2"
                    aria-label=""
                >
                    <option value="">Zona</option>
                    <option value="SMT">SMT</option>
                    <option value="Yerba Buena">Yerba Buena</option>
                    <option value="Tafi Viejo">Tafí Viejo</option>
                    <option value="Barrio Norte">Barrio Norte</option>
                    <option value="Cruz Alta">Cruz Alta</option>
                    <option value="San Pablo">San Pablo</option>
                </Form.Select>

                <Button
                    variant="primary"
                    className="boton-filtro mt-2"
                    onClick={buscarPropiedad}
                >
                    Buscar
                </Button>

                <Button
                    variant="primary"
                    className="boton-filtro mt-2"
                    onClick={resetFiltro}
                    type="submit"
                >
                    <FontAwesomeIcon icon={faUndoAlt} />
                </Button>
            </div>
            <div className={filtro === "propiedades" ? "" : "d-none"}>
                <p className="tamaño-grande card border-2 pb-3 border-top-0 border-start-0 border-end-0 borde-prop color-azul m-0">
                    Propiedades
                </p>
                {propiedades.length === 0 ? (
                    <p className="tamaño-grande mt-3">
                        No se encontraron coincidencias
                    </p>
                ) : (
                    ""
                )}

                <div className="mb-5 d-flex flex-wrap justify-content-around">
                    {currentPosts.map((producto, id) => (
                        <Producto key={id} data={producto} />
                    ))}
                </div>

                <div className="d-none d-sm-flex justify-content-center">
                    <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={propiedades.length}
                        paginate={paginate}
                        currentPage={currentPage}
                    />
                </div>
                <div className="d-flex d-sm-none justify-content-center">
                    <PaginationMobile
                        postsPerPage={postsPerPage}
                        totalPosts={propiedades.length}
                        paginate={paginate}
                        currentPage={currentPage}
                    />
                </div>
            </div>
        </div>
    );
}
