import React from "react";
import NavbarReact2 from "../components/NavbarReact2";
import Politica from "../components/Politica";
import Whatsapp from "../components/Whatsapp";

export default function PoliticaPrivacidad() {
  return (
    <>
      <NavbarReact2 />
      <Politica />
      <Whatsapp />
    </>
  );
}
