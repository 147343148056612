import React from "react";

export default function DescripcionP5() {
  return (
    <div className="container-fluid">
      <div className="row">
          <div className="col-lg-4 p-5 text-center">
            <p>
            Edificios con amenities propios. 
            </p>
          </div>
          <div className="col-lg-4 p-5 text-center color-blanco-fondo2">
            <p>
            Una respuesta habitacional de calidad superior. 
            </p>
          </div>
          <div className=" col-lg-4 p-5 text-center">
          <p>
            Dos y Tres ambientes. Seguridad, confort y calidad superior.
            </p>
          </div>
        </div>
      </div>
  );
}