import React from "react";
import { Image } from "react-bootstrap";

import caja1 from "../assets/caja1.jpg";

export default function Caja1() {
  return (
    <div className="mt-2 mb-2 container-fluid">
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <Image className="w-100 h-100" src={caja1} fluid />
        </div>
        <div className="col-lg-6 col-sm-12 p-5 d-flex flex-column justify-content-center text-center tamaño-grande-escritorio">
          <p>
            Ubicada en una privilegiada zona de Tucumán, a escasos 19km, 15´del
            centro de la ciudad, de la Plaza de la Independencia, enmarcado en
            un marco natural inmejorable, de suaves lomadas verdes, y amplias
            visuales, se encuentra Viento Sur.{" "}
          </p>
          <p>
            El régimen de precipitaciones moderado permite el desarrollo de
            actividades recreativas al aire libre casi de manera permanente. Con
            39.000 m2 de Espacios Verdes y un Club House, Viento Sur posee un
            perímetro de seguridad y acceso controlados 24 hs.
          </p>
          <p>
            Viento Sur te ofrece todas esas cosas que hacen que uno deje de
            respirar y empiece a suspirar.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
