import React from "react";
import ReactPlayer from "react-player";
import Contacto from "./Contacto";

export default function ProyectoSurD() {
  return (
    <>
      <div className="d-flex justify-content-center">
        <ReactPlayer
          className="video cover-container d-none d-sm-flex "
          url={
            "https://res.cloudinary.com/dacvbuvyc/video/upload/v1647897125/Videos/viento_sur_desing_descktop_ocjalv_1_wf3g83.mp4"
          }
          muted
          loop
          playing
          width="100%"
          height="100%"
        ></ReactPlayer>
        <ReactPlayer
          className="video cover-container d-sm-none d-flex"
          url={
            "https://res.cloudinary.com/dacvbuvyc/video/upload/v1647897127/Videos/viento_sur_desing_movile_misfvh_1_srtkke.mp4"
          }
          muted
          loop
          playing
          width="100%"
          height="100%"
        ></ReactPlayer>
      </div>
      <div className="mt-2  p-4 text-center color-azul peso-bold">
        <h1>LA OPORTUNIDAD DE INVERTIR EN TU FUTURO</h1>
      </div>
      <Contacto />
    </>
  );
}
