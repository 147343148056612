import React from "react";
import NavbarReact from "../components/NavbarReact";
import ProyectoSurD from "../components/ProyectoSurD";
import Whatsapp from "../components/Whatsapp";

export default function SurDesign() {
  return (
    <div>
      <NavbarReact />
      <ProyectoSurD />
      <Whatsapp />
    </div>
  );
}
