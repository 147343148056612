import React from "react";
import { Image } from "react-bootstrap";
import Estadisticas from "./Estadisticas";
import Contacto from "../components/Contacto";
import nosotros from "../../src/assets/nosotros.jpg";

export default function NosotrosSchilman() {
  return (
    <>
      <div className="text-center">
        <Image
          className="banner-chico"
          src={nosotros}
          alt="GERMÁN SCHILMAN INMOBILIARIA & EMPRENDIMIENTOS"
          fluid
        />
      </div>
      <div className="color-blanco-fondo text-center p-5">
        <p className="color-azul peso-bold">
          GERMÁN SCHILMAN INMOBILIARIA & EMPRENDIMIENTOS brinda servicios
          inmobiliarios de excelencia. <br />
          Somos una empresa de renombre con años de experiencia en Yerba Buena y
          en San Miguel de Tucumán.
        </p>
      </div>

      <Estadisticas />
      <div className="mt-2 mb-5 px-5">
        <p>
          Como especialista en desarrollo residenciales suburbanos y con fuerte
          presencia en todas las zonas, buscamos mejorar lal calidad de vida de
          nuestros clientes. <br />
          Nuestra empresa asienta su crecimiento sobre bases sólidas como la
          calidad de servicios orientados a la satisfacción del cliente, la
          interacción con los más destacados profesionales en cada rubro desde
          el inicio del proyecto, y la vocación por brindar un asesoramiento
          personalizado de excelencia,{" "}
          <b> GERMÁN SCHILMAN INMOBILIARIA & EMPRENDIMIENTOS </b> logró
          posicionarse como una de las compañías inmobiliarias más consultadas y
          prestigiosas del mercado tucumano.
        </p>
      </div>
      <Contacto />
    </>
  );
}
