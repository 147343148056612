import React from "react";

export default function Estadisticas() {
  return (
    <div className=" mt-5 p-1 color-azul peso-bold">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 p-1 text-center">
            <h1> 10 </h1>
            <p>
              {" "}
              PROYECTOS <br /> EN DESARROLLO
            </p>
          </div>
          <div className="col-lg-4  p-1 text-center ">
            <h1> 10 </h1>
            <p>
              {" "}
              AÑOS DE <br /> EXPERIENCIA
            </p>
          </div>
          <div className=" col-lg-4 p-1 text-center">
            <h1> 100% </h1>
            <p> CLIENTES FELICES</p>
          </div>
        </div>
      </div>
    </div>
  );
}
