import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Producto({ data }) {
    const { id,imagen1, direccion, precio, localidad, operacion } = data;
    return (
        <div className="card-propiedad">
            <Card className="">
                <Link to={"/propiedad/" + id}>
                    <Card.Img
                        className="card-foto img-fluid"
                        variant="top"
                        src={imagen1}
                    />
                </Link>
                <Card.Body className="color-blanco-fondo">
                    <Card.Text className="color-azul peso-bold">
                        {operacion}
                    </Card.Text>
                    <div className="my-2 d-flex">
                        <Card.Text className="tamaño-grande color-blanco color-azul-fondo px-4 py-1">
                            {precio}
                        </Card.Text>
                    </div>
                    <Card.Text className="color-azul peso-bold">
                        {direccion}
                    </Card.Text>
                    <Card.Text className="color-azul peso-bold">
                        {/* <pre>{dato1}</pre> */}
                        {localidad}
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
}
