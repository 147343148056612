/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import logo from "../../src/assets/Logo-fondo-trans_bae9go_jmvyav.png";

export default function NavbarReact() {
  const [navbarPosition, setNavbarPosition] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 600) {
      setNavbarPosition(true);
    } else {
      setNavbarPosition(false);
    }
    // console.log(window.scrollY); //Para ver en el medidor de scroll
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <Navbar
        fixed="top"
        collapseOnSelect
        expand="lg"
        // variant="dark"
        className={
          navbarPosition
            ? "color-blanco-fondo"
            : "color-blanco-fondo transparente"
        }
      >
        <Container>
          <Navbar.Brand href="/">
            <div className="">
              <img className="logo-nav ms-5 me-lg-5" src={logo} alt="logo" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav ">
            <Nav className="ms-auto mt-2 mt-sm-0">
              <Nav.Link
                to="/propiedades"
                exact
                as={NavLink}
                className="ms-auto mx-xl-4 my-auto peso-bold color-azul"
              >
                Propiedades
              </Nav.Link>
              <NavDropdown
                title="Proyectos"
                id="basic-nav-dropdown"
                className="ms-auto mx-xl-4 my-auto dropdown-fondo-blanco peso-bold "
              >
                <NavDropdown.Item to="/proyecto/VillageGolf" exact as={Link}>
                  Village Golf
                </NavDropdown.Item>
                <NavDropdown.Item
                  to="/proyecto/VientoSurDesign"
                  exact
                  as={Link}
                >
                  Viento Sur Design
                </NavDropdown.Item>
                <NavDropdown.Item to="/proyecto/VientoSurTower" exact as={Link}>
                  Viento Sur Tower
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                to="/tasaciones"
                exact
                as={NavLink}
                className="ms-auto mx-xl-4 my-auto peso-bold color-azul"
              >
                Tasaciones
              </Nav.Link>
              <Nav.Link
                to="/nosotros"
                exact
                as={NavLink}
                className="ms-auto mx-xl-4 my-auto peso-bold color-azul"
              >
                Nosotros
              </Nav.Link>
              <Nav.Link
                to="/contactanos"
                exact
                as={NavLink}
                className="ms-auto mx-xl-4 my-auto peso-bold color-azul"
              >
                Contacto
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
