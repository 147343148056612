import React from "react";
import NavbarReact from "../components/NavbarReact";
import Whatsapp from "../components/Whatsapp";
import BannerVientoSur from "../components/BannerVientoSur";
import Contacto from "../components/Contacto";
import Servicios from "../components/Servicios";
import Caja1 from "../components/Caja1";
import Caja2 from "../components/Caja2";
import ProyectosCards from "../components/ProyectosCards";
import CarouselCaja from "../components/CarouselCaja";

export default function Home({ filtro }) {
  return (
    <div>
      <NavbarReact />
      <Whatsapp />
      <BannerVientoSur />
      <Servicios />
      <Caja1 />
      <CarouselCaja />
      <Caja2 />
      <ProyectosCards />
      <Contacto />
    </div>
  );
}
