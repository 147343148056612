import React from "react";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import golf from "../../src/assets/villageGolfSlider.jpg";
import design from "../../src/assets/vientosurdesign1.jpg";
import tower from "../../src/assets/vientoSurTower.jpg";

export default function ProyectosCards() {
  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-lg-4 mt-2 mb-2  text-center d-flex justify-content-center">
          <Card style={{ width: "22rem" }}>
            <Card.Img variant="top" src={golf} />
            <Card.Body>
              <Card.Title className="mb-4 peso-bold">Village Golf</Card.Title>
              <Button variant="info" size="md">
                <Link to="/proyecto/VillageGolf" className="text-white sin-sub">
                  ver +
                </Link>
              </Button>
            </Card.Body>
          </Card>
        </div>
        <div className="col-lg-4 mt-2 mb-2 text-center d-flex justify-content-center ">
          <Card style={{ width: "22rem" }}>
            <Card.Img variant="top" src={design} />
            <Card.Body>
              <Card.Title className="mb-4 peso-bold">
                Viento Sur Design
              </Card.Title>
              <Button variant="info" size="md">
                <Link
                  to="/proyecto/VientoSurDesign"
                  className="text-white sin-sub"
                >
                  ver +
                </Link>
              </Button>
            </Card.Body>
          </Card>
        </div>
        <div className=" col-lg-4 mt-2 mb-2   text-center d-flex justify-content-center">
          <Card style={{ width: "22rem" }}>
            <Card.Img variant="top" src={tower} />
            <Card.Body>
              <Card.Title className="mb-4 peso-bold">
                Viento Sur Tower
              </Card.Title>
              <Button variant="info" size="md">
                <Link
                  to="/proyecto/VientoSurTower"
                  className="text-white sin-sub"
                >
                  ver +
                </Link>
              </Button>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}
