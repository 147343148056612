import React from "react";

export default function Servicios() {
  return (
    <div className="container-fluid">
      <div className="row">
          <div className="col-lg-4 p-5 text-center">
            <p>
            Vivir  fuera de la ciudad no es poca cosa <br />
            Es sinónimo de jerarquía y confort. 
            </p>
          </div>
          <div className="col-lg-4 p-5 text-center color-blanco-fondo2">
            <p>
            Somos el lugar ideal para que vos y tu <br /> 
            familia vivan momentos increíbles en sus vidas. 
            </p>
          </div>
          <div className=" col-lg-4 p-5 text-center">
          <p>
            ¡Viento Sur es el sitio donde está todo el deporte que mas te gusta! <br /> 
            Rugby, Fútbol, Tenis... Son solo algunas de las tantas disciplinas que podrás practicar. 
            </p>
          </div>
        </div>
      </div>
  );
}
