import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Nosotros from "./pages/Nosotros";
import Proyectos from "./pages/VillageGolf";
import Contactanos from "./pages/Contactanos";
import PoliticaPrivacidad from "./pages/PoliticaPrivacidad";
import ProyectoDetalle from "./pages/ProyectoDetalle";
import Tasaciones from "./pages/Tasaciones";
import Propiedades from "./pages/Propiedades";
import VillageGolf from "./pages/VillageGolf";
import SurDesign from "./pages/SurDesign";
import SurTower from "./pages/SurTower";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/Footer";

function App() {
    return (
        <div className="d-flex flex-column min-vh-100">
            <Router>
                <Route>
                    <ScrollToTop></ScrollToTop>
                </Route>
                <Switch>
                    <Route path="/" exact>
                        <Home filtro="banner" />
                    </Route>
                    <Route path="/propiedades">
                        <Propiedades filtro="propiedades" />
                    </Route>
                    <Route path="/nosotros">
                        <Nosotros />
                    </Route>
                    <Route path="/proyectos">
                        <Proyectos />
                    </Route>
                    <Route path="/proyecto/VillageGolf">
                        <VillageGolf />
                    </Route>
                    <Route path="/proyecto/VientoSurDesign">
                        <SurDesign />
                    </Route>
                    <Route path="/proyecto/VientoSurTower">
                        <SurTower />
                    </Route>
                    <Route path="/propiedad/:id">
                        <ProyectoDetalle />
                    </Route>
                    <Route path="/contactanos">
                        <Contactanos />
                    </Route>
                    <Route path="/tasaciones">
                        <Tasaciones />
                    </Route>
                    <Route path="/politica">
                        <PoliticaPrivacidad />
                    </Route>
                </Switch>
                <Footer />
            </Router>
        </div>
    );
}

export default App;
