import React from "react";

export default function Whatsapp() {
    return (
        <div>
            <a href="https://wa.link/rcp4nj">
                <img
                    className="fixed-bottom ms-auto me-2 me-sm-4 mb-sm-4 icono-whatsapp"
                    src="https://res.cloudinary.com/dkz/image/upload/v1634256960/Schilman/Recurso_1_ftvvv6.png"
                    alt=""
                />
            </a>
        </div>
    );
}
