import React from "react";
import { Image } from "react-bootstrap";
import Contacto from "../components/Contacto";
import tasa from "../../src/assets/tasaciones.png";

export default function Tasa() {
  return (
    <>
      <div>
        <h1 className=" tamaño-grande mt-5 mb-5 mx-4 text-center color-azul peso-bold">
          Conocé el precio justo de tu inmueble
        </h1>
      </div>
      <div className="text-center">
        <Image className=" banner-chico" src={tasa} alt="tasacion" fluid />
      </div>
      <div className="color-blanco-fondo text-center">
        <p className="p-5 color-azul peso-bold">
          Con el objetivo de que conozcas en forma concreta el valor de venta o
          alquiler de tu propiedad, realizamos las tasaciones de los inmuebles{" "}
          <br />
          en forma personalizada, a través de una inspección ocular del mismo y
          confeccionamos un informe detallado de la tasación.
        </p>
      </div>
      <div>
        <h1 className=" tamaño-grande mt-5 mx-4  mb-5 text-center color-azul peso-bold">
          Asesoramos a nuestros clientes en formas objetiva y real para
          permitirles <br /> tomar la decisión más acertada sobre la propiedad
          valuada.
        </h1>
      </div>
      <Contacto />
    </>
  );
}
