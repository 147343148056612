import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="color-azul-fondo color-blanco mt-auto">
      <div className="container px-4 pt-4">
        <div className="row">
          <div className="col-12 col-xl-4 text-center text-md-start d-flex justify-content-center mx-auto mt-3 mt-md-0 ">
            <ul className="list-unstyled">
              <li className="tamaño-medio text-center peso-bold">
                <p>
                  German Schilman <br /> Inmobiliaria & emprendimientos
                </p>
              </li>
              <li className="text-center">
                Horarios de atención:
                <p>
                  <span className="peso-bold">
                    Casa Central - Yerba Buena:{" "}
                  </span>{" "}
                  <br /> Lunes a Viernes: 9 a 18 hs.
                </p>
              </li>
            </ul>
          </div>
          <div className="col-12 col-xl-4 text-center text-md-start d-flex justify-content-center  mt-3 mt-md-0   p-1">
            <ul className="list-unstyled">
              <li>
                <p className="tamaño-medio text-center peso-bold mt-2 mb-0">
                  Casa central - Yerba Buena
                </p>
                <div className="text-center"> Av. Aconquija 1810</div>
              </li>
            </ul>
          </div>
          <div className="col-12 col-xl-4 text-center text-md-start d-flex justify-content-center mb-1 mt-3 mt-md-0  p-1">
            <ul className="list-unstyled">
              <li className="text-center">
                <p>
                  WhatsApp -{" "}
                  <span className="tamaño-medio text-center peso-bold">
                    {" "}
                    0381 677-2685 <br />
                  </span>
                  <span className="mt-1">
                    {" "}
                    Síguenos en nuestras <br /> redes sociales{" "}
                  </span>
                </p>
              </li>
              <div className="d-flex justify-content-center me-1">
                <a
                  className="fs-2   text-decoration-none text-white"
                  href="https://www.facebook.com/GermanSchilmanInmobiliariayEmprendimientos.S.A/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>
                    <FontAwesomeIcon icon={faFacebookF} />
                  </span>
                </a>
                <a
                  className="fs-2 ms-2 text-decoration-none text-white"
                  href="https://www.instagram.com/inmobiliariagermanschilman/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>
                    <FontAwesomeIcon icon={faInstagram} />
                  </span>
                </a>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div className=" p-1 color-blanco-fondo p-2">
        <div className="text-center text-md-start my-2 d-flex justify-content-center text-dark">
          <div>
            <a
              className="text-decoration-none text-dark"
              href="https://www.dkzstudio.com/"
              target="_blank"
              rel="noreferrer"
            >
              Product by DKZ studio{" "}
            </a>
          </div>
          <div>-Copyright &copy;{new Date().getFullYear()}-</div>
          <div>
            <Link className="text-decoration-none text-dark" to="/politica">
              Política de privacidad
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
