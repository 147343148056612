import React from "react";
import NavbarReact2 from "../components/NavbarReact2";
import Tasa from "../components/Tasa";
import Whatsapp from "../components/Whatsapp";

export default function Tasaciones() {
  return (
    <div>
      <NavbarReact2 />
      <Tasa />
      <Whatsapp />
    </div>
  );
}
