import React from "react";

export default function Politica() {
  return (
    <div className="container ">
      <h5 className="peso-bold  mt-5 mb-4">POLÍTICAS DE PRIVACIDAD</h5>
      <p>
        <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> respeta tu
        derecho a la privacidad. Esta política resume la información de
        identificación personal que podemos obtener y la forma en que podemos
        usarla. Esta política también describe otros temas importantes
        relacionadas con tu privacidad.
      </p>
      <div>
        <h1 className="peso-bold  fs-6">
          Finalidad de la recolección de datos.
        </h1>
        <p>
          Registro de las bases de datos <br /> Los datos requeridos y/o
          solicitados a terceros serán, en cada caso, utilizados con la única y
          exclusiva finalidad con la que fueron recabados. La información
          respecto de la finalidad para la cual se obtienen será dada en cada
          caso, al momento de brindar el titular sus datos personales, por
          cualquier medio que fuera. En particular, tratándose de clientes, la
          información será recabada, en general y sin perjuicio de lo que se
          informe al titular al momento de brindar sus datos personales, con la
          finalidad de proveer a una adecuada gestión administrativa y de
          contacto; para fines de publicidad, venta directa, alquileres y
          similares. Los datos obtenidos serán archivados en bases de datos
          propiedad de{" "}
          <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b>, las cuales
          se llevan en un todo de conformidad con lo dispuesto por la ley de
          protección de Datos Personales N° 25.326, encontrándose las mismas
          inscriptas ante la Dirección Nacional de Protección de Datos
          Personales, autoridad competente en la materia.{" "}
        </p>
      </div>
      <div>
        <h1 className="peso-bold fs-6"> Su consentimiento</h1>
        <p>
          Al utilizar el Sitio de Internet de{" "}
          <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b>, usted
          acepta los términos de esta Política de Privacidad. Al enviar
          información a través de este sitio, usted da su consentimiento para la
          recopilación, el uso y la divulgación de dicha información, según se
          describe en esta Política y en las Declaraciones de Privacidad
          vigentes del Sitio Web o del País. Si Ud. no autorizase la
          recopilación, uso y/o divulgación de su información en la forma
          descrita en esta Política, por favor no utilice los Sitios de Internet
          de <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b>
        </p>
      </div>
      <div>
        <h1 className="peso-bold fs-6">
          Límites para la recopilación, uso y divulgación de información
          personal
        </h1>
        <p>
          En la medida que así lo requieran las leyes vigentes, siempre que
          <b> Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> recopile
          información personal en un Sitio de Internet propio,
          <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> :
        </p>
        <p>
          <ul>
            <li>
              le proporcionará un aviso oportuno y correcto sobre las prácticas
              de datos de Germán Schilman Inmobiliaria y Emprendimientos S.A.;
            </li>
            <li>
              recopilará, usará, divulgará y transferirá su información personal
              solo con su consentimiento, que podrá ser explícito o implícito,
              según la sensibilidad de la información personal, los requisitos
              legales y otros factores;
            </li>
            <li>
              recopilará su información personal solo para fines específicos y
              limitados. La información que recopilemos será pertinente,
              adecuada y no excesiva respecto de los fines para los que se la
              recopiló;
            </li>
            <li>
              procesará su información personal de manera coherente con los
              propósitos para los cuales se recopiló originalmente o para los
              que usted otorgó posteriormente su consentimiento;
            </li>
            <li>
              tomará medidas comercialmente razonables a fin de asegurar que la
              información personal sea fiable para el uso propuesto, exacta,
              completa y que, cuando sea necesario, se mantenga actualizada;
            </li>
            <li>
              no utilizará su información personal para fines de
              comercialización directa sin brindarle la oportunidad de no
              participar (“opt-out”); y
            </li>
          </ul>
        </p>
      </div>
      <div>
        <h1 className="peso-bold fs-6">
          Información recopilada en Sitios de Internet de
          <b> Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> y cómo
          puede ser utilizada.
        </h1>
        <p>
          <ul>
            <li>
              <b>Información que usted proporciona de forma voluntaria:</b>{" "}
              <br />
              Germán Schilman Inmobiliaria y Emprendimientos S.A. recopila la
              información que usted proporciona de forma voluntaria y
              consciente, a fin de beneficiarse de una característica o
              participar en una actividad de un Sitio de Internet de{" "}
              <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> Por
              ejemplo,
              <b> Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> puede
              recopilar su información de modo que usted pueda recibir alertas
              por correo electrónico, o bien cuando solicita información de las
              propiedades, hace una pregunta o nos envía un mensaje con
              comentarios. En muchos casos, esta información puede incluir la
              recopilación de datos personales. Germán Schilman Inmobiliaria y
              Emprendimientos S.A. sólo utiliza su información a los fines para
              los cuales usted la proporcionó. Por ejemplo, si proporciona su
              dirección de correo electrónico o número telefónico, utilizaremos
              esos datos para los fines por los cuales los proporcionó. Además,
              podemos utilizar la información que recopilemos en los Sitios de
              Internet de Germán Schilman Inmobiliaria y Emprendimientos S.A.
              para diversos objetivos comerciales, como servicio al cliente,
              productos y servicios, o para proporcionarles a usted la
              información y las ofertas que consideremos que pueden ser de su
              interés. Asimismo, podemos eliminar toda la información de
              identificación personal y usar los demás datos con fines
              estadísticos, científicos o históricos.
            </li>
            <li>
              <b>Información recogida automáticamente:</b> <br />
              <b> Germán Schilman Inmobiliaria y Emprendimientos S.A.</b>{" "}
              recopila la información que su explorador web nos envía
              automáticamente. Esta información generalmente incluye la
              dirección IP de su proveedor de servicios de Internet, el nombre
              de su sistema operativo (por ejemplo, Macintosh® o Windows®) y el
              nombre y la versión de su explorador (por ejemplo, Internet
              Explorer® o Safari®). La información que recibamos depende de la
              configuración de su explorador web. Compruebe su explorador si
              desea saber qué información envía o cómo puede cambiar la
              configuración. Utilizamos esta información para que nos ayude a
              mejorar nuestros sitios y hacerlos más compatibles con la
              tecnología que utilizan nuestros visitantes de Internet.
            </li>
            <li>
              Cookies y tecnologías similares: <br /> Una “cookie” es un pequeño
              archivo de datos que se instala en su equipo cuando visita un
              sitio web. Las cookies y tecnologías similares pueden mejorar su
              experiencia de usuario al guardar sus preferencias, personalizar
              su experiencia en línea y, en algunas ocasiones, proporcionarles
              anuncios publicitarios adaptados a sus intereses. Todos los Sitios
              de Internet de{" "}
              <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b>
              usan “cookies de sesión”. Una cookie de sesión no le identifica de
              forma individual y caduca cuando se cierra el explorador. Por
              ejemplo, al utilizar el catálogo de productos de{" "}
              <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> , se
              instala una cookie de sesión para guardar las páginas que ha
              visitado. Podemos utilizar esta información para proporcionarle
              recomendaciones de otros productos que puedan interesarle.
            </li>
            <li>
              Uso de la configuración “No seguir” del explorador <br /> Los
              Sitios de Internet de{" "}
              <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> son
              compatibles con la función “No seguir” del explorador. “No seguir”
              es una configuración del explorador que proporciona una elección
              única, simple y persistente para no participar del seguimiento de
              terceros en la web. Si su explorador web tiene habilitada la
              configuración “No seguir”, las cookies de publicidad no se
              establecerán en las propiedades web de Germán Schilman
              Inmobiliaria y Emprendimientos S.A..
            </li>
            <li>
              Configuración de cookies en su ordenador
              <br />
              En cualquier momento que visite un Sitio de Internet de{" "}
              <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b>, al
              hacer clic en el enlace “Preferencias de cookies”, en la parte
              inferior de cada página, puede acceder a información sobre las
              cookies y cambiar su configuración. Si bien no tiene la obligación
              de aceptar cookies cuando visite un Sitio de Internet de Germán
              Schilman Inmobiliaria y Emprendimientos S.A., es posible que no
              pueda utilizar todas las funciones del sitio si rechaza
              determinadas cookies. Además, su explorador puede permitirle
              ajustar la configuración para aceptar o rechazar cookies, o
              enviarle alertas cuando una cookie se instale en su ordenador.
            </li>
          </ul>
        </p>
      </div>
      <div>
        <h1 className="peso-bold fs-6">Uso y divulgación de su información</h1>
        <p>
          <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> no venderá
          la información personal recopilada de sus Sitios de Internet a agentes
          de listas de correo sin su consentimiento explícito.{" "}
          <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> no
          divulgará la información personal acerca de los visitantes de nuestro
          Sitio de Internet, excepto los casos que se describen a continuación:
        </p>
        <p>
          <ul>
            <li>
              Salvo que se indique específicamente, podemos utilizar la
              información recopilada en los sitios de Internet de{" "}
              <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> para
              mejorar el contenido de nuestro sitio, personalizar el sitio de
              acuerdo con sus preferencias, comunicarle información (si la
              solicitó) con fines de comercialización e investigación, y para
              cualquier otro fin especificado en un sitio web.
            </li>
            <li>
              <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> puede
              divulgar su información personal a otras unidades comerciales de{" "}
              <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> Al
              hacerlo, esas otras unidades comerciales de{" "}
              <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b>{" "}
              utilizarán su información de una manera que sea coherente con los
              objetivos para los cuales se recopiló originalmente (o para los
              cuales usted otorgue su consentimiento posteriormente).
            </li>
            <li>
              <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> también
              puede compartir su información personal con terceros que
              contratemos para que nos presten servicios de apoyo. Dichos
              terceros tienen la obligación de utilizar la información personal
              que hayamos compartido solo para prestar servicios en nuestro
              nombre y tratar su información personal de manera estrictamente
              confidencial, conforme a todas las leyes de protección de datos y
              privacidad vigentes.
            </li>
            <li>
              En algunos casos,{" "}
              <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> puede
              compartir su información personal con terceros que estén asociados
              con nosotros para proporcionar productos y servicios a nuestros
              clientes. Si es así, les exigiremos a nuestros socios comerciales
              que utilicen dicha información de manera coherente con los fines
              para los cuales se recopiló originalmente (o para los que usted
              otorgue su consentimiento posteriormente), y solo en la medida en
              que lo permita esta Política, las Declaraciones de Privacidad
              vigentes del Sitio Web o del País, y todas las leyes de protección
              de datos y privacidad vigentes.
            </li>
            <li>
              En ciertas circunstancias limitadas, podemos compartir o
              transferir información personal a terceros que no estén
              relacionados. Por ejemplo, podemos proporcionar la información
              personal a un tercero: (i) si usted lo solicita, (ii) para cumplir
              con un requisito legal o de un tribunal, (iii) para investigar un
              posible delito, como el robo de identidad, (iv) si está
              relacionado con la venta, la compra, la fusión, la reorganización,
              la liquidación o la disolución de Germán Schilman Inmobiliaria y
              Emprendimientos S.A. o de una unidad comercial de Germán Schilman
              Inmobiliaria y Emprendimientos S.A., o (v) en circunstancias
              similares. Si ocurre alguno de estos eventos, tomaremos las
              medidas correspondientes para proteger su información personal.
            </li>
          </ul>
        </p>
      </div>
      <div>
        <h1 className="peso-bold fs-6">Seguridad de su información personal</h1>
        <p>
          Su información personal se almacenará en las bases de datos de{" "}
          <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> o en las
          bases de datos que mantengan nuestros proveedores de servicios. Germán
          Schilman Inmobiliaria y Emprendimientos S.A. mantiene medidas
          preventivas razonables para proteger la seguridad, confidencialidad e
          integridad de su información personal. Por ejemplo, utilizamos una
          tecnología segura para transferir la información personal en Internet.
          Limitamos el acceso a la información personal a aquellos que tengan
          una necesidad comercial. Si bien utilizamos medidas de seguridad para
          ayudar a proteger su información personal contra divulgaciones no
          autorizadas, uso indebido o alteración, tenga en cuenta que ninguna
          transmisión de Internet es completamente segura o libre de errores.
          Germán Schilman Inmobiliaria y Emprendimientos S.A. no será
          responsable de las violaciones a la seguridad que estén fuera de
          nuestro control en una medida razonable.
        </p>
      </div>
      <div>
        <h1 className="peso-bold fs-6">
          Enlaces a Sitios de Internet de Terceros
        </h1>
        <p>
          Los Sitios de Internet de{" "}
          <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> pueden
          contener vínculos a sitios de Internet que no son administrados por{" "}
          <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> Estos
          vínculos se proporcionan como un servicio y no implican ningún tipo de
          aprobación de las actividades o del contenido de estos sitios, ni la
          asociación con sus operadores. Le instamos a que revise la política de
          privacidad publicada en los sitios web que visite antes de utilizar el
          sitio o de proporcionar información personal.
        </p>
      </div>
      <div>
        <h1 className="peso-bold fs-6">Acceso a la información personal</h1>
        <p>
          Según la legislación vigente en la República Argentina, el titular de
          los datos personales tiene la facultad de ejercer el derecho de acceso
          a los mismos en forma gratuita a intervalos no inferiores a seis
          meses, salvo que se acredite un interés legítimo al efecto conforme lo
          establecido en el artículo 14, inciso 3 de la Ley Nº 25.326. La
          DIRECCION NACIONAL DE PROTECCION DE DATOS PERSONALES, Órgano de
          Control de la Ley Nº 25.326, tiene la atribución de atender las
          denuncias y reclamos que se interpongan con relación al incumplimiento
          de las normas sobre protección de datos personales. Los interesados
          podrán ejercer su derecho de acceso, rectificación o eliminación de
          sus datos personales: (a) presentándose en Colectora Oeste de
          Panamericana 576, Garín, Provincia de Buenos Aires, con su DNI y
          completando el formulario destinado a tal fin; o bien (b)
          contactándose por vía telefónica al (0348) 465 9400 donde se le
          indicará el procedimiento aplicable y la eventual documentación que se
          requiera completar. El horario de atención en ambos casos será los
          días hábiles de 8:30 a 12:00 hs.
        </p>
      </div>
      <div>
        <h1 className="peso-bold fs-6">Más información:</h1>
        <p>
          Dirección Nacional de Protección de Datos Personales <br /> (LINK:
          <a
            href=" http://www.jus.gob.ar/datospersonales.aspx/"
            target="_blank"
            rel="noreferrer"
          >
            http://www.jus.gob.ar/ datospersonales.aspx/
          </a>
          )
        </p>
      </div>
      <div>
        <h1 className="peso-bold fs-6">
          Conservación de la información personal
        </h1>
        <p>
          El tiempo que conservemos su información personal variará y dependerá
          del objetivo y del uso de la información recopilada. Existen
          requisitos legales para que conservemos algunos tipos de datos por
          períodos específicos. De lo contrario, retendremos dicha información
          por el tiempo que sea necesario a los fines por los que se recopilaron
          dichos datos.
        </p>
      </div>
      <div>
        <h1 className="peso-bold fs-6">Menores</h1>
        <p>
          La mayoría de los Sitios de Internet de{" "}
          <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> no están
          destinados a menores de 18 años.{" "}
          <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> no
          solicitará ni recopilará a sabiendas la información personal de
          menores o acerca de menores en sus Sitios de Internet, excepto que lo
          permitan las leyes vigentes.
        </p>
      </div>
      <div>
        <h1 className="peso-bold fs-6">
          Preguntas acerca de esta Política o de nuestras Declaraciones de
          Privacidad
        </h1>
        <p>
          Si tiene alguna pregunta acerca de esta Política o de la Declaración
          de Privacidad del Sitio Web o del País, o sobre el uso que le demos a
          su información personal, comuníquese con nosotros por correo
          electrónico.
        </p>
      </div>
      <div>
        <h1 className="peso-bold fs-6">
          Cambios en esta Política y nuestras Declaraciones de Privacidad
        </h1>
        <p>
          Los cambios que se realicen en esta Política de Privacidad se
          publicarán en este sitio. <b>Germán Schilman Inmobiliaria y Emprendimientos S.A.</b> se reserva el derecho de actualizar o modificar
          esta Política y las Declaraciones de Privacidad de Sitio Web en
          cualquier momento y sin previo aviso. Las modificaciones se aplicarán
          solo a la información personal que recopilemos después de esta
          publicación. Esta Política de Privacidad fue revisada por última vez
          el 25 de octubre de 2021.xº
        </p>
      </div>
    </div>
  );
}
