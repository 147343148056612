import React from 'react'
import Contacto from '../components/Contacto'
import NavbarReact2 from '../components/NavbarReact2'
import Whatsapp from '../components/Whatsapp'

export default function Contactanos() {
    return (
        <div>
            <NavbarReact2 />
            <Whatsapp /> 
            <Contacto />
        </div>
    )
}
