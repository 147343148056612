import React from "react";
import NavbarReact2 from "../components/NavbarReact2";
import Productos from "../components/Productos";
import Whatsapp from "../components/Whatsapp";

export default function Propiedades({ filtro }) {
  return (
    <div>
      <NavbarReact2 />
      <Whatsapp />
      <Productos filtro={filtro} />
    </div>
  );
}
