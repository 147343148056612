import React from "react";
import { Carousel, Image } from "react-bootstrap";
import viento1 from "../../src/assets/1vientosur.jpg";
import viento2 from "../../src/assets/2vientosur.jpg";
import viento3 from "../../src/assets/3vientosur.jpg";

export default function CarouselCaja() {
  return (
    <div>
      <Carousel fade indicators={false} controls={false}>
        <Carousel.Item>
          <Image
            className="w-100 carousel-caja"
            src={viento1}
            alt="First slide"
            fluid
          />
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Image
            className="w-100 carousel-caja"
            src={viento2}
            alt="Second slide"
            fluid
          />

          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Image
            className="w-100 carousel-caja"
            src={viento3}
            alt="Third slide"
            fluid
          />

          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
