import React from "react";
import NavbarReact from "../components/NavbarReact";
import ProyectoSurT from "../components/ProyectoSurT";
import Whatsapp from "../components/Whatsapp";

export default function SurTower() {
  return (
    <div>
      <NavbarReact />
      <ProyectoSurT />
      <Whatsapp />
    </div>
  );
}
