import React from "react";
import emailjs from "emailjs-com";
import { Col, Form, InputGroup, Row, Button } from "react-bootstrap";
import { useState } from "react";
import Swal from "sweetalert2";
import { useRef } from "react";

export default function Formulario() {
  const [validated, setValidated] = useState(false);
  const form = useRef();

  /*funcion para mandar msj al gmail*/
  const sendEmail = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === true) {
      e.stopPropagation();
      emailjs
        .sendForm(
          "service_7oxo3qp",
          "template_yxyzc0i",
          e.target,
          "user_w2KOWaUeRb5S2knXxEc8L"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      Swal.fire({
        icon: "success",
        title: "Mensaje enviado con éxito",
        showConfirmButton: false,
        timer: 2000,
      });
      form.reset();
      setValidated(false); //
    } else {
      setValidated(true);
    }
  };

  return (
    <div>
      <Form
        ref={form}
        noValidate
        validated={validated}
        onSubmit={sendEmail}
        className="mx-auto form mb-5 p-2 "
      >
        <div>
          <div className="card-body">
            <Row className="mb-3">
              <Form.Group
                className="tamaño-medio peso-medium"
                as={Col}
                md="12"
                controlId="validationCustom03"
              >
                <Form.Label>Nombre*</Form.Label>
                <Form.Control type="text" required name="name" />
                <Form.Control.Feedback type="invalid">
                  Ingrese su nombre completo por favor.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="12" controlId="validationCustom04">
                <Form.Label>WhatsApp*</Form.Label>
                <Form.Control
                  className="mb-3 mb-sm-0"
                  type="text"
                  required
                  name="cel"
                />
                <Form.Control.Feedback type="invalid">
                  Ingrese su número de WhatsApp completo por favor.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom03">
                <Form.Label className="mt-3">Email*</Form.Label>
                <Form.Control type="email" required name="email" />
                <Form.Control.Feedback type="invalid">
                  Ingrese su Email por favor.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom03">
                <Form.Label className="mt-3">Mensaje</Form.Label>
                <InputGroup>
                  <Form.Control as="textarea" name="msj" />
                </InputGroup>
                <span className="mt-1 text-black-50">* Campo requerido</span>
              </Form.Group>
            </Row>
          </div>
        </div>

        <div className="d-flex justify-content-center ">
          <Button
            className="w-75 text-white"
            variant="info"
            size="lg"
            type="submit"
            value="Send"
          >
            <b>Enviar mensaje</b>
          </Button>
        </div>
      </Form>
    </div>
  );
}
