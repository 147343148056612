import React from "react";
import NavbarReact from "../components/NavbarReact";
import NavbarReact2 from "../components/NavbarReact2";
import Whatsapp from "../components/Whatsapp";
import NosotrosSchilman from "../components/NosotrosSchilman";

export default function Nosotros() {
  return (
    <div>
      <div className="d-none d-sm-block">
        <NavbarReact />
      </div>
      <div className="d-sm-none d-block">
        <NavbarReact2 />
      </div>
      <Whatsapp />
      <NosotrosSchilman />
    </div>
  );
}
