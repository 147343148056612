import React from "react";
import { Image } from "react-bootstrap";
import DescripcionVG from "./DescripcionVG";
import Contacto from "./Contacto";
import ReactPlayer from "react-player";
import cuadrada1 from "../assets/villageGolf/cuadradaVillage.jpg";
import cuadrada2 from "../assets/villageGolf/cuadradaVillage2.jpg";
import cuadrada3 from "../assets/villageGolf/cuadradaVillage3.jpg";

import dormitorio1 from "../assets/villageGolf/dormitorio1.jpg";
import dormitorio2 from "../assets/villageGolf/monoambiente2.jpg";
import dormitorio3 from "../assets/villageGolf/dormitorio3.jpg";
import dormitorio4 from "../assets/villageGolf/monoambiente4.jpg";
import dormitorio5 from "../assets/villageGolf/dormitorios5.jpg";

export default function ProyectoVillageG() {
  return (
    <>
      <div className="d-flex justify-content-center">
        <ReactPlayer
          className="video cover-container d-none d-sm-flex "
          url={
            "https://res.cloudinary.com/dacvbuvyc/video/upload/v1647895790/Videos/Village_golf_descktop_guzijx_1_alnu01.mp4"
          }
          muted
          loop
          playing
          width="100%"
          height="100%"
        ></ReactPlayer>
        <ReactPlayer
          className="video cover-container d-sm-none d-flex"
          url={
            "https://res.cloudinary.com/dacvbuvyc/video/upload/v1647895789/Videos/Village_golf_movile_e8nmkh_1_zmptvv.mp4"
          }
          muted
          loop
          playing
          width="100%"
          height="100%"
        ></ReactPlayer>
      </div>
      <div className="color-blanco-fondo p-5">
        <p>
          Ubicado en un punto estratégico de Yerba Buena, conectándose con la
          naturaleza y el corazón de la ciudad, cuenta con 52 departamentos
          monoambiente, 1 y 2 dormitorios con amplías terrazas-balcón, asador y
          excelentes vistas al cerro San Javier o al campo de golf. Posee cerca
          perimetral con sistema de alarma y monitoreo para darte la seguridad
          que vos y tu familia necesitan.
        </p>
      </div>
      <DescripcionVG />

      <div className="container d-flex flex-wrap justify-content-between">
        <Image
          className="imagen-proyecto1 mx-5"
          src={cuadrada1}
          rounded
          fluid
          alt="imagen-1"
        />
        <Image
          className="imagen-proyecto1 mx-5 mt-1 mb-2"
          src={cuadrada2}
          rounded
          fluid
          alt="imagen-2"
        />
        <Image
          className="imagen-proyecto1 mx-5"
          src={cuadrada3}
          rounded
          fluid
          alt="imagen-3"
        />
      </div>
      <div className="container text-center">
        <h1 className="text-center color-azul mt-5 mx-4 tamaño-grande peso-bold">
          Conectate con la naturaleza, en el corazón de Yerba Buena. ¡Solo en
          Village Golf! <br />
          Av. Solano Vera al 1000
        </h1>
      </div>
      <div className="container d-flex flex-wrap justify-content-around mt-5">
        <Image
          className="mx-1 mt-2  mb-2  imagen-proyecto1b"
          src={dormitorio1}
          rounded
          fluid
          alt="1 dormitorio"
        />
        <Image
          className="mx-1 mt-2  mb-2 imagen-proyecto1b mt-1 mb-2"
          src={dormitorio2}
          rounded
          fluid
          alt="MonoAmbiente"
        />
        <Image
          className="mx-1 mt-2  mb-2  imagen-proyecto1b"
          src={dormitorio3}
          rounded
          fluid
          alt="1 dormitorio"
        />
        <Image
          className="mx-1 mt-2  mb-2  imagen-proyecto1b"
          src={dormitorio4}
          rounded
          fluid
          alt="MonoAmbiente"
        />
        <Image
          className="mx-1 mt-2  mb-2 imagen-proyecto1b"
          src={dormitorio5}
          rounded
          fluid
          alt="2 Dormitorios"
        />
      </div>
      <Contacto />
    </>
  );
}
