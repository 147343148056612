import React from "react";
import DescripcionP5 from "./DescripcionP5";
import Contacto from "./Contacto";
import ReactPlayer from "react-player";

export default function ProyectoSurT() {
  return (
    <>
      <div className="d-flex justify-content-center">
        <ReactPlayer
          className="video cover-container d-none d-sm-flex "
          url={
            "https://res.cloudinary.com/dacvbuvyc/video/upload/v1647897374/Videos/Village_tower_descktop_d46hxt_1_vvlog9.mp4"
          }
          muted
          loop
          playing
          width="100%"
          height="100%"
        ></ReactPlayer>
        <ReactPlayer
          className="video cover-container d-sm-none d-flex"
          url={
            "https://res.cloudinary.com/dacvbuvyc/video/upload/v1647897374/Videos/Village_tower_movile_ltqapf_1_q2ikeg.mp4"
          }
          muted
          loop
          playing
          width="100%"
          height="100%"
        ></ReactPlayer>
      </div>
      <div className="color-blanco-fondo text-center  p-5">
        <p>
          Viento Sur es otro concepto de vida urbana. <br />
          Te contamos que próximamente en el Country contaremos con Viento Sur
          Tower.
        </p>
      </div>
      <DescripcionP5 />
      <Contacto />
    </>
  );
}
