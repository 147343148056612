import React from "react";
import { Carousel } from "react-bootstrap";

export default function SliderProyectoDetalle({producto}) {
    const { imagen1,imagen2,imagen3,imagen4,imagen5,imagen6,imagen7,imagen8,imagen9,imagen10, } = producto;
    // console.log(producto)
    return (
        <Carousel fade indicators={false}>
            <Carousel.Item className="">
                <img
                    className="d-block w-100"
                    src={imagen1}
                    alt="imagen 1"
                />
            </Carousel.Item>
            {imagen2===""?"":<Carousel.Item>
                <img
                    className="d-block w-100"
                    src={imagen2}
                    alt="imagen 2"
                />
            </Carousel.Item>}
            {imagen3===""?"":<Carousel.Item>
                <img
                    className="d-block w-100"
                    src={imagen3}
                    alt="imagen 3"
                />
            </Carousel.Item>}
            {imagen4===""?"":<Carousel.Item>
                <img
                    className="d-block w-100"
                    src={imagen4}
                    alt="imagen 4"
                />
            </Carousel.Item>}
            {imagen5===""?"":<Carousel.Item>
                <img
                    className="d-block w-100"
                    src={imagen5}
                    alt="imagen 5"
                />
            </Carousel.Item>}
            {imagen6===""?"":<Carousel.Item>
                <img
                    className="d-block w-100"
                    src={imagen6}
                    alt="imagen 6"
                />
            </Carousel.Item>}
            {imagen7===""?"":<Carousel.Item>
                <img
                    className="d-block w-100"
                    src={imagen7}
                    alt="imagen 7"
                />
            </Carousel.Item>}
            {imagen8===""?"":<Carousel.Item>
                <img
                    className="d-block w-100"
                    src={imagen8}
                    alt="imagen 8"
                />
            </Carousel.Item>}
            {imagen9===""?"":<Carousel.Item>
                <img
                    className="d-block w-100"
                    src={imagen9}
                    alt="imagen 9"
                />
            </Carousel.Item>}
            {imagen10===""?"":<Carousel.Item>
                <img
                    className="d-block w-100"
                    src={imagen10}
                    alt="imagen 10"
                />
            </Carousel.Item>}
        </Carousel>
    );
}
