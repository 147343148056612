import React from "react";
import { Image } from "react-bootstrap";
import caja2 from "../../src/assets/caja2.jpg";
export default function Caja2() {
  return (
    <div className="container-fluid mt-2">
      <div className="row ">
        <div className="col-lg-6 col-sm-12 p-5 ">
          <div className="mt-5 p-5  tamaño-grande-escritorio">
            <h1>Amenities: </h1>
            <ul>
              <li> Huerta Orgánica</li>
              <li> Piscina</li>
              <li> Cancha de Tenis + Multicanchas</li>
              <li> Bicisendas y Area</li>
              <li> Yoga</li>
              <li> Futuro Mall</li>
              <li> Estación de Servicio</li>
              <li> Juegos</li>
              <li> Canchas de Fútbol</li>
              <li> Canal Polonio</li>
              <li> Quincho con Vestuarios</li>
              <li> Gimnasio al Aire Libre</li>
              <li> Bicisendas y Área Deportiva (canchas de fútbol y rugby)</li>
              <li> Kids Club</li>
              <li> Cine al Aire Libre</li>
            </ul>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12">
          <Image className="w-100" src={caja2} fluid />
        </div>
        <div className="color-blanco-fondo py-3 text-center mt-2">
          <h1>Nuestros otros proyectos</h1>
          <p>las mejores oportunidades de inversión</p>
        </div>
      </div>
    </div>
  );
}
