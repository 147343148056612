import Formulario from "./Formulario";

export default function Contacto() {
  return (
    <div>
      <h1 className="text-center color-azul mt-5 mx-4 tamaño-grande peso-bold">
        Déjanos tus datos <br /> y un asesor especializado te contactará
      </h1>
      <div className="mt-3 mx-4 color-azul peso-regular text-center">
        <p className="">
          También puedes acudir a nuestra oficina <br />
          Yerba Buena - Av. Aconquija 1810
        </p>
        <p>
          Horarios de atención: Lunes a Viernes: 9 a 18 hs. <br />
        </p>
      </div>
      <Formulario />
    </div>
  );
}
